import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { BookContext } from "../../context/BookContext";
import { UserContext } from "../../context/UserContext";
import { LoaderContext } from "../../context/loader-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import ChatBotIcon from "../../images/chat-bot-icon.svg";
import UserImage from "../../images/chat-user.svg";
import SessionChevronRight from "../../images/session-chevron-right.svg";
import THUMBDOWN from "../../images/thumb-down2.svg";
import THUMBUP from "../../images/thumb-up2.svg";
import DeleteIcon from "../../images/trash-disabled.svg";
import myAxios from "../../myaxios";
import CustomMarkdown from "../../utils/custom-markdown";
import { parseErrorResponse } from "../../utils/utils";
import FeedbackAddDataSource from "./feedback-add-to-data-source";
import { MarkDownContent } from "../publicComponent/styled-public";
import ConfirmationBox from "../utility-component/confirmation-box";
import AnswerSourcePopup from "../utility-component/answer-source-popup";

interface Color {
  selected: boolean;
}

const ParentSection = styled(Col)`
  padding: 1rem;
  flex-direction: row;
  justify-content: space-between;
`;

const ColumnSection = styled.div`
  background: #fff;
  flex-direction: column;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  width: 100%;
`;

const ContentParent = styled.div`
  flex-direction: column;
`;

const HeadingText = styled.h2`
  font-size: 20px;
  color: #33303c;
  font-weight: 600;
  padding: 0.5rem;
`;

const Subheading = styled.p`
  font-size: 16px;
  color: #33303c;
  font-weight: 400;
  padding-left: 0.5rem;
`;

const DataSection = styled.div`
  flex-direction: row;
  height: 65vh;
`;
const Sidebar = styled(Col)``;

const SelectedLead = styled(Col)`
  border: 1px solid #eaeaea;
  flex-direction: column;
`;

const SessionList = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  width: 100%;
  height: 65vh;
  overflow: auto;
`;
// const ImageTagSource = styled.img``;
const SessionListItem = styled.li`
  background: ${(props: Color) => (props.selected ? "#1585b5" : "#fff")};
  padding: 10px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const SessionItemText = styled.div`
  flex-direction: column;
`;
const SessionItemArrow = styled.div``;

const SessionUserName = styled.p`
  color: ${(props: Color) =>
    props.selected ? "#fff" : "rgba(51, 48, 60, 0.6)"};
  margin: 0;
  font-size: 16px;
`;

const SessionThorugh = styled.p`
  color: ${(props: Color) =>
    props.selected ? "#fff" : "rgba(51, 48, 60, 0.6)"};
  margin: 0;
  font-size: 14px;
`;

const SessionContentHeader = styled.div`
  background: #2f3349;
  padding: 20px;
  color: #ffffffcc;
`;

const SessionContentBody = styled.div`
  flex-direction: column;
  overflow: auto;
`;

export const SignleQuestionAnswerSection = styled.div`
  margin: 5px;
  flex-direction: column;
`;

export const ResponseSection = styled.div`
  padding: 8px 8px;
  color: #282c34;
  background: #ffffff;
  border-bottom: 1px solid #ebe8f0;
  align-items: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const QuestionSection = styled.div`
  padding: 8px 8px;
  background: #ffffff;
  align-items: center;
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
    font-size: 14px;
  }
`;

export const QuestionAnswerSection = styled.div`
  flex-direction: column;
  width: 100%;
`;
const DeleteDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const UpdateButton = styled.button`
  background: #1585b5;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 14px;
  cursor: pointer;
  width: 200px;
  height: 35px;
  padding: 0.3rem 1.5rem;
  margin-right: 0.3rem;
  font-weight: bold;
  letter-spacing: 0.8px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ChatbotSessions = () => {
  //Context
  const { user } = useContext(UserContext);
  const { setShowLoader } = useContext(LoaderContext);
  const { selectedBook, setShowAnswerSourcePopup } = useContext(BookContext);
  //States
  const [confirmationAction, setConfirmationAction] = useState<string>("");
  const [chatHistoryId, setchatHistoryId] = useState("");
  const [chatHistoryRating, setChatHistoryRating] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isAddAsSourceVisible, setIsAddAsSourceVisible] = useState(false);
  const [chatSessions, setChatSessions] = useState<any>();
  const [selectedChatSession, setSelectedChatSession] = useState<any>();
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);
  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");
  const [selectedChatSessionToDelete, setSelectedChatSessionToDelete] =
    useState<any>();
  const [allAnswerSource, setAllAnswerSource] = useState([]);

  let isLast = false;

  useEffect(() => {
    setShowLoader(true);
    if (selectedBook && selectedBook._id)
      myAxios
        .post(
          process.env.REACT_APP_SERVER_URL +
            `/lead-chat-sessions/paginated-chat-sessions`,
          {
            bookId: selectedBook && selectedBook._id,
          }
        )
        .then((response) => {
          if (response.data && response.data.success) {
            setChatSessions(response.data.data.chatSessions);
            setSelectedChatSession(response.data.data.chatSessions[0]);
            setShowLoader(false);
          } else {
            setShowLoader(false);
            NotificationManager.error(
              response.data.message
                ? response.data.message
                : "Unable to fetch chat session, please try later!"
            );
          }
        })
        .catch((error) => {
          // handle error
          setShowLoader(false);
          NotificationManager.error(parseErrorResponse(error));
        });
    // eslint-disable-next-line
  }, [selectedBook && selectedBook._id]);

  const deleteSource = (isLast: boolean) => {
    if (isLast) {
      setConfirmationBoxMessage(
        "Are you sure you want to delete the remaining session of this chatbot? This would also delete chat history. Please confirm?"
      );
    } else {
      setConfirmationBoxMessage("Do you really want to delete this session?");
    }
  };

  const actionBodyTemplate = (id: any) => {
    if (selectedChatSession?.sources?.length === 1) {
      isLast = true;
    }

    return (
      <>
        <img
          style={{ cursor: "pointer", width: "20px" }}
          src={DeleteIcon}
          alt={"Delete"}
          className="w-4rem"
          onClick={() => {
            // if (dataSource.indexStatus === 1) {
            //   NotificationManager.warning(
            //     "The source is still in progress, please wait!"
            //   );
            //   return;
            // }
            deleteSource(isLast);
            setConfirmationAction("delete");
            setShowUtilityConfirmationBox(true);
            setSelectedChatSessionToDelete(id);
          }}
        />
      </>
    );
  };

  const confirmClick = (action: string) => {
    if (action === "delete" && selectedChatSessionToDelete) {
      setShowLoader(true);
      const sessionId =
        selectedChatSession &&
        (selectedChatSession._id || selectedChatSession.id);
      myAxios
        .delete(
          `${process.env.REACT_APP_SERVER_URL}/lead-chat-sessions/${sessionId}`
        )
        .then((response) => {
          if (response.data && response.data.success) {
            // Update cueent book
            // setSelectedBook(response.data.data);
            setShowUtilityConfirmationBox(false);
            const newchatSessions = chatSessions.filter(
              // eslint-disable-next-line
              (newData: any) => newData._id != selectedChatSessionToDelete
            );
            setChatSessions(newchatSessions);
            if (newchatSessions && newchatSessions.length > 0) {
              setSelectedChatSession(newchatSessions[0]);
            } else {
              setSelectedChatSession(undefined);
            }

            NotificationManager.success("Session deleted successfully");
            // if (isLast) {
            //   navigate("/");
            // }

            trackEvent({
              email: getUsersEmail(user),
              eventCategory: EventCategory.CHATBOT,
              event: ChatBotEvents.USER_DELETE_LEAD_CHATBOT_SESSIONS,
              subCategory: SubCategory.EVENT,
              bookId: selectedBook?._id,
              rawData: selectedChatSession,
            });
          } else {
            setShowUtilityConfirmationBox(false);
            NotificationManager.error(
              response.data && response.data.message
                ? response.data.message
                : "Not able to delete session, Please try again!"
            );
          }
          setShowLoader(false);
        })
        .catch((error) => {
          setShowUtilityConfirmationBox(false);
          NotificationManager.error(parseErrorResponse(error));
          setShowLoader(false);
        });
    }
  };

  const togglePopup = ({ chatHistory }: any) => {
    setIsAddAsSourceVisible(!isAddAsSourceVisible);
    setchatHistoryId(chatHistory.id || chatHistory._id);
    setQuestion(chatHistory.question);
    setAnswer(chatHistory.answer);
    setChatHistoryRating(chatHistory.rating);
  };

  return (
    <>
      <ParentSection sm={12}>
        <ColumnSection>
          <ContentParent>
            <HeadingText>Chatbot Sessions</HeadingText>
            <Subheading>
              {/* You can use different theme settings in different formats */}
            </Subheading>
          </ContentParent>
          <DataSection>
            <Sidebar sm={3}>
              <SessionList>
                {chatSessions &&
                  chatSessions.length > 0 &&
                  chatSessions.map((chatSession: any) => {
                    return (
                      <SessionListItem
                        selected={selectedChatSession._id === chatSession._id}
                        onClick={() => {
                          setSelectedChatSession(chatSession);
                        }}
                      >
                        <SessionItemText>
                          <SessionUserName
                            selected={
                              selectedChatSession._id === chatSession._id
                            }
                          >
                            {chatSession.name
                              ? chatSession.name
                              : chatSession.email
                              ? chatSession.email
                              : chatSession.mobileNumber
                              ? chatSession.mobileNumber
                              : "Guest User"}
                          </SessionUserName>
                          <SessionThorugh
                            selected={
                              selectedChatSession._id === chatSession._id
                            }
                          >
                            {chatSession.source === "bubblechat"
                              ? "Embedded Chat Lead"
                              : chatSession.source === "share"
                              ? "Shared URL Lead"
                              : chatSession.source === "iframe"
                              ? "iFrame Lead"
                              : chatSession.source === "teamShared"
                              ? `Team Shared Lead  -  ${
                                  chatSession.userRole
                                    ? chatSession.userRole
                                    : "viewer"
                                }`
                              : "N/A"}
                          </SessionThorugh>
                        </SessionItemText>
                        <SessionItemArrow>
                          <img src={SessionChevronRight} alt="select" />
                        </SessionItemArrow>
                      </SessionListItem>
                    );
                  })}
              </SessionList>
            </Sidebar>
            {selectedChatSession && (
              <SelectedLead sm={9}>
                <SessionContentHeader>
                  <Col>
                    {selectedChatSession.name
                      ? selectedChatSession.name
                      : "N/A"}{" "}
                    &nbsp;|&nbsp;{" "}
                    {selectedChatSession.email
                      ? selectedChatSession.email
                      : "N/A"}{" "}
                    &nbsp;|&nbsp;{" "}
                    {selectedChatSession.mobileNumber
                      ? selectedChatSession.mobileNumber
                      : "N/A"}{" "}
                    &nbsp;|&nbsp;
                    {selectedChatSession.createdAt
                      ? moment(selectedChatSession.createdAt).format(
                          "DD/MM/YYYY hh:mm A"
                        )
                      : "N/A"}{" "}
                    &nbsp;|&nbsp;{" "}
                    <img width="20" src={THUMBUP} alt="user_image" />
                    &nbsp; {selectedChatSession.goodCount}
                    &nbsp;|&nbsp;{" "}
                    <img width="20" src={THUMBDOWN} alt="user_image" />
                    &nbsp; {selectedChatSession.badCount}
                  </Col>
                  <DeleteDiv>
                    {actionBodyTemplate(selectedChatSession._id)}
                  </DeleteDiv>
                </SessionContentHeader>
                <SessionContentBody>
                  {selectedChatSession &&
                    selectedChatSession.chatHistories &&
                    selectedChatSession.chatHistories.length > 0 &&
                    selectedChatSession.chatHistories.map(
                      (chatHistory: any) => {
                        return (
                          <SignleQuestionAnswerSection>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <QuestionSection>
                                <img
                                  width="20"
                                  src={UserImage}
                                  alt="user_image"
                                />
                                &nbsp;&nbsp;
                                <h5>{chatHistory.question}</h5>
                              </QuestionSection>

                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "5px 8px",
                                  }}
                                >
                                  <>
                                    {chatHistory && (
                                      <UpdateButton
                                        onClick={() => {
                                          setShowAnswerSourcePopup(true);
                                          setAllAnswerSource(
                                            chatHistory.answerSourceList &&
                                              chatHistory.answerSourceList
                                                .length > 0
                                              ? chatHistory.answerSourceList
                                              : []
                                          );
                                        }}
                                      >
                                        Show Source
                                      </UpdateButton>
                                    )}
                                    {chatHistory &&
                                      !chatHistory.isAddDataSource &&
                                      selectedBook &&
                                      // eslint-disable-next-line
                                      selectedBook.type != "book" && (
                                        <UpdateButton
                                          onClick={() =>
                                            togglePopup({
                                              chatHistory,
                                            })
                                          }
                                        >
                                          Add to Data Source
                                        </UpdateButton>
                                      )}

                                    {(chatHistory &&
                                      chatHistory.rating === "Good") ||
                                    chatHistory.rating === "Bad" ? (
                                      <img
                                        width="30"
                                        src={
                                          chatHistory.rating === "Good"
                                            ? THUMBUP
                                            : THUMBDOWN
                                        }
                                        alt=""
                                      />
                                    ) : null}
                                  </>
                                </div>
                                {isAddAsSourceVisible && (
                                  <FeedbackAddDataSource
                                    editPopUp={setIsAddAsSourceVisible}
                                    question={question}
                                    answer={answer}
                                    chatHistoriesId={chatHistoryId}
                                    chatHistoryRating={chatHistoryRating}
                                    setChatSessions={setChatSessions}
                                    setSelectedChatSession={
                                      setSelectedChatSession
                                    }
                                    setAllAnswerSource={setAllAnswerSource}
                                    answerSourceList={
                                      chatHistory.answerSourceList
                                    }
                                  />
                                )}
                              </>
                            </div>
                            <ResponseSection>
                              <div>
                                <img
                                  width="20"
                                  src={ChatBotIcon}
                                  alt="user_image"
                                  style={{
                                    display: "flex",
                                    alignSelf: "baseline",
                                  }}
                                />
                                &nbsp;&nbsp;
                                {chatHistory.answer &&
                                chatHistory.answer.length > 0 ? (
                                  <MarkDownContent
                                    color={"rgba(51, 48, 60, 1)"}
                                  >
                                    <CustomMarkdown text={chatHistory.answer} />
                                  </MarkDownContent>
                                ) : (
                                  <h5>{`${chatHistory.answer}`}</h5>
                                )}
                              </div>
                            </ResponseSection>
                          </SignleQuestionAnswerSection>
                        );
                      }
                    )}
                </SessionContentBody>
              </SelectedLead>
            )}
          </DataSection>
        </ColumnSection>
        {showUtilityConfirmationBox && (
          <ConfirmationBox
            setShow={setShowUtilityConfirmationBox}
            name="Confirmation"
            message={confirmationBoxMessage}
            okButtonText="Yes"
            action={confirmationAction}
            okButtonClick={confirmClick}
          />
        )}
        <AnswerSourcePopup allAnswerSource={allAnswerSource} />
      </ParentSection>
    </>
  );
};

export default ChatbotSessions;
