import axios from "axios";
import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import { LoaderContext } from "../../context/loader-context";
import { ChatSource } from "../../enums";
import {
  EventCategory,
  ShareChatbotEvents,
  Source,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { trackEvent } from "../../event-utils/track-event";
import BulbVector from "../../images/bulb-vector.svg";
import SearchArrow from "../../images/chat-arrow.svg";
import ChatBotIcon from "../../images/chat-bot-icon.svg";
import UserImage from "../../images/chat-user.svg";
import ChatLiveIcon from "../../images/chatLive-face-agent1.svg";
import BubbleChatLiveIcon from "../../images/drop-chat-icon.svg";
import EscalateEmailIcon from "../../images/escEmailIcon.svg";
import LoaderGif from "../../images/loader.gif";
import THUMBDOWNINACTIVE from "../../images/thumb-down-inactive.svg";
import THUMBDOWN from "../../images/thumb-down2.svg";
import THUMBUPINACTIVE from "../../images/thumb-up-inactive.svg";
import THUMBUP from "../../images/thumb-up2.svg";
import TypingLoading from "../../images/typingLoading.gif";
import { Book } from "../../types";
import useDebounce from "../../use-debounce";
import CustomMarkdown from "../../utils/custom-markdown";
import { CHATBOT_WAIVER_TEXT } from "../../utils/place-holder-consts";
import { isValidEmail, parseErrorResponse } from "../../utils/utils";
import { Chat } from "../book-chat/chat";
import InlineMethodLoginForm from "../bubble-chat/inline-method-loginform";
import BubbleCustomGreetingText from "../bubble-chat/bubble-custom-greeting-text";
import ChatCommanHeader from "../bubble-chat/chat-comman-header";
import SuggestedQuestionsSharedIframeSection from "../bubble-chat/suggested-questions-shared-iframe";
import MagicLinkLoginPopup from "../magic-link-login-popup";
import AgreeComponent from "../utility-component/agree-component";
import EscalateEmailPopup from "../utility-component/escalate-email-popup";
import * as BookChatStyles from "./styled-public";
// import { UserContext } from "../../context/UserContext";
import { PublicContext } from "../../context/PublicContext";
import { getDeviceInfoForTrackEvent } from "../../event-utils/dropchat-event-track";

const SharedBookChat = (sharedBot: any) => {
  const { setGuestUserInfo, guestUserInfo } = useContext(PublicContext);
  const { innerWidth: width } = window;
  const isMobileWidth = width < 1000;

  const { setShowLoader } = useContext(LoaderContext);
  const [currentSessionHistory, setCurrentSessionHistory] = useState<any[]>([]);
  // eslint-disable-next-line
  const [publicUserQuestionAskedCount, setPublicUserQuestionAskedCount] =
    useState<number>(0);
  // eslint-disable-next-line
  const [trialQuestionLimit, setTrialQuestionLimit] = useState<number>(5);
  // eslint-disable-next-line
  const [isTrialQuestionLoaded, setTrialQuestionLoaded] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  // eslint-disable-next-line
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const { bookId } = useParams();

  const [currentBookUser, setCurrentBookUser] = useState<any>();

  const [localBook, setLocalBook] = useState<Book>({});
  const [chatbotUserEmail, setChatbotUserEmail] = useState<string>();
  const [showOverlay, setShowOverlay] = useState(false);

  const [fileProcessingMessage, setFileProcessingMessage] =
    useState<string>("");

  const [showLeadForm, setShowLeadForm] = useState<string>("none");
  const [showConditionalLeadForm, setShowConditionalLeadForm] =
    useState<boolean>(false);

  const [leadSettings, setLeadSettings] = useState({
    bookId: bookId,
    title: "Welcome to Dropchat",
    customText: "Fill out form to start chatting",
    loginForm: "none",
    isUserName: true,
    isEmail: true,
    isMobileNumber: true,
    nameText: "Full Name",
    emailText: "Email",
    mobileNumberText: "Phone",
    footerBranding: "Powered By Dropchat",
    footerBrandingLink: "https://dropchat.co/",
    conditionalMessage:
      "You have reached the guest visitor limit on chat questions. Please login to continue with chat.",
  });

  const [leadThemeSettings, setLeadThemeSettings] = useState({
    bookId: bookId,
    source: "share",
    titleBackgroundColor: "#1585b5",
    questionBackgroundColor: "#000000",
    questionTextColor: "#ffffff",
    answerBackgroundColor: "#f8f7fa",
    answerTextColor: "#33303c",
    questionTextFieldColor: "#000000",
  });

  const [sampleQuestions, setSampleQuestions] = useState([]);
  const [showSampleQuestions, setShowSampleQuestions] = useState(false);

  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");

  const [currentChatSession, setCurrentChatSession] = useState<any>();

  const [questionValue, setQuestionValue] = useState("");
  const [loginViaTrigger, setLoginViaTrigger] = useState<boolean>(false);

  const [socialMediaDetails, setSocialMediaDetails] = useState<any>();
  const [customGreetingText, setCustomGreetingText] = useState<any>();

  const [isMessageInterface, setIsMessageInterface] = useState<boolean>(true);

  const [waiverSettings, setWaiverSettings] = useState({
    bookId: bookId,
    source: "bubble",
    waiverText: CHATBOT_WAIVER_TEXT,
    isWaiver: "",
    id: "",
  });

  const [showAgreePopup, setShowAgreePopup] = useState<boolean>(false);
  const [showEscEmailPopup, setShowEscEmailPopup] = useState<boolean>(false);

  const [firstMessageAgreePopup, setFirstMessageAgreePopup] =
    useState<boolean>(true);
  const [userIP, setUserIP] = useState("");

  const [isConditional, setConditional] = useState<boolean>(false);

  const [triggerCondition, setTriggerCondition] = useState<string>("");
  const [triggerValue, setTriggerValue] = useState<number>(0);
  const [isResponseOn, setIsResponseOn] = useState<boolean>(false);
  const [isInlineForm, setIsInlineForm] = useState();

  const [formRef, setFormRef] = useState<any>();
  const [questionId, setQuestionId] = useState<any>("");
  const [escAnswer, setEscAnswer] = useState<any>("");
  const [escQuestion, setEscQuestion] = useState<any>("");

  const [showEscEmail, setEscEmail] = useState(false);
  const [showChatLive, setChatLive] = useState(false);
  const [chatURL, setChatURL] = useState<string>("");
  const [userLoggedInChatBot, setUserLoggedInChatBot] = useState();
  const [userLoggedDetails, setUserLoggedDetails] = useState<any>();
  const [userFullName, setUserFullName] = useState({
    firstName: undefined,
    lastName: undefined,
  });
  const [chatbotDetails, setChatbotDetails] = useState<any>();

  console.log("currentBookUser is, ", currentBookUser);

  const getUserBrowserData = async () => {
    const browser = await getDeviceInfoForTrackEvent();
    setGuestUserInfo(browser);
  };
  useEffect(() => {
    getUserBrowserData();
    // eslint-disable-next-line
  }, [setGuestUserInfo]);

  useDebounce(
    () => {
      setLoading(true);
      if (bookId) {
        axios
          .get(
            process.env.REACT_APP_SERVER_URL +
              "/public/getFileProcessingMessage"
          )
          .then((response) => {
            if (response && response.data) {
              setFileProcessingMessage(response.data.data);
            }
          });
        axios
          .get(
            process.env.REACT_APP_SERVER_URL +
              "/public/get-trial-question-limit"
          )
          .then((response) => {
            if (response && response.data && response.data.data > 0) {
              setTrialQuestionLoaded(true);
              setTrialQuestionLimit(response.data.data);
            }
          });
        axios
          .get(process.env.REACT_APP_SERVER_URL + "/books/" + bookId)
          .then((response) => {
            if (response.data) {
              setLocalBook(response.data);
              setChatbotUserEmail(response.data.user.email);
              setCurrentBookUser(response.data.user.id);
              setPublicUserQuestionAskedCount(
                response.data.user.questionAskedCount
              );
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    },
    [setLocalBook, bookId],
    1000
  );

  const chatStart = (userQuery: string) => {
    if (currentChatSession) {
      chatWithDropchat(userQuery);
    } else {
      setShowLoader(true);
      const dataToSave = {
        bookId: bookId,
        name: nameValue,
        email: emailValue,
        mobileNumber: phoneValue,
        source: "share",
        chatLeadSessionId: currentChatSession
          ? currentChatSession._id
          : undefined,
        extraDetails: guestUserInfo,
      };

      if (dataToSave.email || dataToSave.mobileNumber || dataToSave.name) {
        let data = JSON.stringify(dataToSave);
        localStorage.setItem(`userLoggedIn${bookId}`, data);
      }

      axios
        .post(
          process.env.REACT_APP_SERVER_URL + `/lead-chat-sessions`,
          dataToSave
        )
        .then((response) => {
          if (response.data && response.data.success) {
            let chatSession: any = localStorage.getItem(
              "shared_current_lead_chat_session"
            );
            if (chatSession === null) {
              setCurrentChatSession(response.data.data);
              let objString = JSON.stringify(response.data.data);
              localStorage.setItem(
                `shared_current_lead_chat_session${bookId}`,
                objString
              );
            } else {
              if (bookId === chatSession.book) {
                setCurrentChatSession(chatSession);

                localStorage.setItem(
                  `shared_current_lead_chat_session${bookId}`,
                  chatSession
                );
              } else {
                setCurrentChatSession(response.data.data);
                let objString = JSON.stringify(response.data.data);
                localStorage.setItem(
                  `shared_current_lead_chat_session${bookId}`,
                  objString
                );
              }
            }
          }
          setShowLoader(false);
          chatWithDropchat(userQuery, response?.data?.data);
        })
        .catch((error) => {
          // handle error
          // NotificationManager.error(parseErrorResponse(error));
          setShowLoader(false);
          chatWithDropchat(userQuery);
        });
    }
  };
  const getFullAnswer = async (id: any) => {
    const jsonObject = JSON.parse(id);
    const leadChatHistoryResId = jsonObject.leadChatHistoryRes_id;

    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/lead-chat-histories/${leadChatHistoryResId}`
      )
      .then((res: any) => {
        // console.log("res.data.data ---", res.data.data);
        setIsResponseOn(false);
        if (res.data) {
          // updateUserSubscription(res.data.data.currentSubscription);
          //setActivePlan(res.data.data.currentSubscription);

          const data = res.data;

          setCurrentSessionHistory((oldQuestions: any[]) => {
            if (oldQuestions.length > 0) {
              oldQuestions[oldQuestions.length - 1]._id = data._id;
              oldQuestions[oldQuestions.length - 1].id = data.id;
              oldQuestions[oldQuestions.length - 1].answer = data.answer;

              return [...oldQuestions];
            } else return [];
          });
          checkAndShowLoginForm();
          setShowOverlay(false);
        }
      })
      .catch((error) => {
        setShowOverlay(false);
        setIsResponseOn(false);
      });
  };

  const chatWithDropchat = async (userQuery: string, session?: any) => {
    const currentSession = currentChatSession || session;

    setShowOverlay(true);
    setCurrentSessionHistory(
      currentSessionHistory
        ? [
            ...currentSessionHistory,
            { question: userQuery, answer: "", isChatEnd: false },
          ]
        : [{ question: userQuery, answer: "" }]
    );
    trackEvent({
      email: emailValue || "PUBLIC",
      eventCategory: EventCategory.CHATBOT,
      event: ShareChatbotEvents.USER_ASKED_QUESTION_ON_SHARE_CHAT,
      subCategory: SubCategory.EVENT,
      source: Source.SHARE,
      bookId: bookId,
      chatbotUserEmail: chatbotUserEmail,
      leadChatSession: currentChatSession && currentChatSession.id,
      rawData: {
        question: userQuery,
      },
    });

    const chatHistory = loginViaTrigger ? currentSessionHistory : [];
    setLoginViaTrigger(false);

    const requestBody = {
      question: userQuery,
      bookId: bookId,
      source: ChatSource.share,
      chatLeadSessionId: currentSession ? currentSession._id : undefined,
      chatHistory,
      questions:
        currentSessionHistory && currentSessionHistory.length > 0
          ? currentSessionHistory.map((ques) => ques.question)
          : [],
    };
    const chat = new Chat(
      requestBody,
      (token: string) => {
        // console.log("Recived token -- ", token);

        setCurrentSessionHistory((oldQuestions: any[]) => {
          if (oldQuestions.length > 0) {
            oldQuestions[oldQuestions.length - 1].answer = oldQuestions[
              oldQuestions.length - 1
            ].answer
              ? oldQuestions[oldQuestions.length - 1].answer + token
              : token;
            return [...oldQuestions];
          } else return [];
        });
      },
      (data: any) => {
        // console.log("Here to get final response ..", data);
        // setShowOverlay(false);

        setCurrentSessionHistory((oldQuestions: any[]) => {
          if (oldQuestions.length > 0) {
            oldQuestions[oldQuestions.length - 1].isChatEnd = true;
            return [...oldQuestions];
          } else return [];
        });

        getFullAnswer(data);
      },
      (data: any) => {
        // console.log("Here in to get falied response --- ", data);
        NotificationManager.error(data);
        setIsResponseOn(false);
        setCurrentSessionHistory((oldQuestions: any[]) => {
          if (oldQuestions.length > 0) {
            oldQuestions.pop(); //splice(oldQuestions.length - 1, 1);
            return [...oldQuestions];
          } else return [];
        });
      },
      ""
      // user?.jwt
    );

    await chat.startChat();
  };

  const submitQuestion = (values: any, actions: any) => {
    axios
      .post(
        process.env.REACT_APP_SERVER_URL +
          "/check-if-question-charactor-limit-remain",
        { characterLength: values.prompt.length, userId: currentBookUser }
      )
      .then((response) => {
        setShowLoader(false);
        if (response && response.data && response.data.success) {
          // chatWithDropchat(values.prompt);
          chatStart(values.prompt);
        } else {
          setIsResponseOn(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Not able to get result, please try again later"
          );
        }
      })
      .catch((error) => {
        setIsResponseOn(false);
        // handle error
        setShowLoader(false);

        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setUserIP(res.data.ip);
  };

  const checkAndShowLoginForm = () => {
    if (isConditional && triggerCondition === "message") {
      if (!currentChatSession || !currentChatSession._id) {
        if (currentSessionHistory.length + 1 >= triggerValue) {
          if (showLeadForm === "conditional") {
            setLoginViaTrigger(true);
            setShowConditionalLeadForm(true);
          }
        }
      } else {
        if (currentSessionHistory.length + 1 >= triggerValue) {
          if (showLeadForm === "conditional") {
            setLoginViaTrigger(true);
            setShowConditionalLeadForm(true);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (triggerCondition === "second") {
      // Need to add timer to show login form

      setTimeout(() => {
        //  Need to check and show login form
        if (!currentChatSession || !currentChatSession._id) {
          // Need to show login form
          if (showLeadForm === "conditional") {
            setLoginViaTrigger(true);
            setShowConditionalLeadForm(true);
          }
        }
      }, triggerValue * 1000);
    }
    // eslint-disable-next-line
  }, [triggerCondition]);

  const rating = (questionId: any, rating: any) => {
    setCurrentSessionHistory((oldQuestions: any[]) => {
      if (oldQuestions.length > 0) {
        const questions = oldQuestions.map((question) => {
          if (question._id === questionId)
            return { ...question, rating: rating };
          else return question;
        });

        return questions;
      }
      return oldQuestions;
    });
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/questions-and-answers-rating`,
        { questionId: questionId, rating: rating }
      )
      .then((res) => {
        if (res.data.success) {
        }
      });
  };

  const chatLive = (leadChatSession: any, bookId: any) => {
    try {
      window.open(chatURL, "_blank");
    } catch (error) {}

    const dataToSave = {
      leadChatSession: leadChatSession,
      bookId: bookId,
    };
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/chat-live-info`, dataToSave)
      .then((res) => {
        // console.log("res --- ", res);
        /*  if (res.data.redirectLink) {
          NotificationManager.success("Chat live redirecting.");
        } else {
          NotificationManager.error(
            "Unable to process your request, please try later"
          );
        } */
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/escalate-emails/${bookId}`)
      .then((res) => {
        if (res && res.data && res.data.data) {
          setEscEmail(res.data.data.isEscalateEmail);
          setChatLive(res.data.data.isChatLive);
          setChatURL(res.data.data.redirectLink);
        } else {
          /*  NotificationManager.error(
            "Unable to process your request, please try later"
          ); */
        }
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setShowLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/public/get-chatbot-details/${bookId}`
      )
      .then((response) => {
        if (response && response.data) {
          setChatbotDetails(response.data.data);
        }
      });
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/lead-login-settings/${bookId}`)
      .then((res) => {
        setShowLoader(false);
        if (res.data.data) {
          setLeadSettings(res.data.data);
          setShowLeadForm(res.data.data.loginForm);
          setIsInlineForm(res.data.data.isInlineForm);
          setShowLoader(false);

          if (res.data.data.loginForm === "conditional") {
            setConditional(true);
            // console.log("res.data.data", res.data.data);

            if (res.data.data.isNoOfMessage) {
              setTriggerCondition("message");
              setTriggerValue(res.data.data.noOfMessage);
              // console.log(
              //   "res.data.data.noOfMessage",
              //   res.data.data.noOfMessage
              // );
            }
            if (res.data.data.isNoOfSecond) {
              setTriggerCondition("second");
              setTriggerValue(res.data.data.noOfSecond);
            }
          }
        }
      })
      .catch((error) => {
        setShowLoader(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/lead-theme-settings/${bookId}/share`
      )
      .then((res) => {
        if (res.data.data) {
          setLeadThemeSettings(res.data.data);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        setShowLoader(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/lead-sample-questions/${bookId}`
      )
      .then((res) => {
        if (res.data.data) {
          setSampleQuestions(res.data.data);
          if (res.data.data.length > 0) {
            setShowSampleQuestions(true);
          }
        }
      })
      .catch((error) => {
        setShowLoader(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/chatbot-waiver-settings/${bookId}`
      )
      .then((res) => {
        if (res.data.data) {
          setWaiverSettings(res.data.data);
        }
      })
      .catch((error) => {
        setShowLoader(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/seo-metas/getSocialMedia/${bookId}`
      )
      .then((res) => {
        if (res.data) {
          setSocialMediaDetails(res.data);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        setShowLoader(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/chatbot-greeting-settings/${bookId}`
      )
      .then((res) => {
        if (res.data.data) {
          setCustomGreetingText(res.data.data);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        // NotificationManager.error(parseErrorResponse(error));
        setShowLoader(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/chatbot-interface-settings/${bookId}`
      )
      .then((res) => {
        if (res.data.data) {
          setIsMessageInterface(res.data.data.interfaceType === "message");
        } else {
          setIsMessageInterface(false);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
    getData();
    const localUser = localStorage.getItem("terms");
    if (localUser) {
      setFirstMessageAgreePopup(false);
    } else setFirstMessageAgreePopup(true);
    // eslint-disable-next-line
  }, []);

  const validatedLoginSettings = () => {
    if (leadSettings.isUserName)
      if (!nameValue || nameValue.length === 0) {
        NotificationManager.error("Name Required!");
        return false;
      }

    if (leadSettings.isEmail) {
      if (!emailValue || emailValue.length === 0) {
        NotificationManager.error("Email required!");
        return false;
      }

      if (!isValidEmail(emailValue)) {
        NotificationManager.error("Enter valid email address!");
        return false;
      }
    }

    if (leadSettings.isMobileNumber)
      if (!phoneValue || phoneValue.length === 0) {
        NotificationManager.error("Mobile number required!");
        return false;
      }

    return true;
  };

  const createUpdateChatSession = (isFromLoginForm: boolean) => {
    const dataToSave = {
      bookId: bookId,
      name: nameValue,
      email: emailValue,
      mobileNumber: phoneValue,
      source: "share",
      chatLeadSessionId: currentChatSession
        ? currentChatSession._id
        : undefined,
      extraDetails: guestUserInfo,
    };

    if (dataToSave.email || dataToSave.mobileNumber || dataToSave.name) {
      let data = JSON.stringify(dataToSave);
      localStorage.setItem(`userLoggedIn${bookId}`, data);
    }

    axios
      .post(
        process.env.REACT_APP_SERVER_URL + `/lead-chat-sessions`,
        dataToSave
      )
      .then((response) => {
        if (response.data && response.data.success) {
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Details submitted successfully."
          );

          setCurrentChatSession(response.data.data);
          if (isFromLoginForm) setShowLeadForm("none");
          setShowConditionalLeadForm(false);
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const submitTerms = (selectedBtn: any) => {
    const dataToSave = {
      bookId: bookId,
      source: "share",
      isAgree: selectedBtn === "agree" ? true : false,
      ipAddress: userIP,
      leadChatSession:
        currentChatSession && currentChatSession.id && currentChatSession.id,
      chatbotWaiverSettingId: waiverSettings.id,
    };
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + `/chatbot-waiver-stats`,
        dataToSave
      )
      .then((response) => {
        if (response.data.data.isAgree) {
          localStorage.setItem("terms", "true");
          setFirstMessageAgreePopup(false);
        }
        if (response.data && response.data.success) {
          // setShowLeadForm("none");
          setShowAgreePopup(false);
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
          setShowAgreePopup(false);
        }
      })
      .catch((error) => {
        // handle error
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const handleIncSampleQuestionCount = (questionId: any) => {
    axios
      .post(
        process.env.REACT_APP_SERVER_URL +
          `/lead-sample-questions/${questionId}`
      )
      .then((response) => {})
      .catch((error) => {
        // handle error
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  useEffect(() => {
    let leadchatSession: any = localStorage.getItem(
      `shared_current_lead_chat_session${bookId}`
    );
    let retrievedObj: any = JSON.parse(leadchatSession);

    if (leadchatSession !== null && bookId === retrievedObj.book) {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL +
            `/lead-chat-histories/session/${retrievedObj._id}`
        )
        .then((response) => {
          if (response.data && response.data.success) {
            let res: any[] = response.data.message;

            const updatedMessages = res.map((message) => {
              return { ...message, isChatEnd: true };
            });
            setCurrentSessionHistory(updatedMessages);

            if (bookId === retrievedObj.book) {
              setCurrentChatSession(retrievedObj);
            } else {
              setCurrentChatSession({});
            }
          }

          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  const handleCheckUserloggedin = () => {
    let sharedChatBotUserLoggedIn: any = localStorage.getItem(
      `userLoggedIn${bookId}`
    );
    let retrievedLoginDetails: any = JSON.parse(sharedChatBotUserLoggedIn);

    if (
      retrievedLoginDetails &&
      retrievedLoginDetails.bookId === bookId &&
      retrievedLoginDetails.source === "share"
    ) {
      let names =
        retrievedLoginDetails && retrievedLoginDetails.name.split(" ");

      // setUserFullName({
      //   firstName: names[0],
      //   lastName: names.pop(),
      // });

      if (names && names.length > 0) {
        setUserFullName({
          firstName: names[0],
          lastName: names.length > 1 ? names.pop() : "",
        });
      } else {
        setUserFullName({
          firstName: undefined,
          lastName: undefined,
        });
      }
      setUserLoggedInChatBot(retrievedLoginDetails.bookId);
      setUserLoggedDetails(retrievedLoginDetails);
    }
  };
  useEffect(() => {
    handleCheckUserloggedin();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {socialMediaDetails && (
        <Helmet>
          <title>{socialMediaDetails.socialMediaTitle}</title>
          <meta
            name="description"
            content={socialMediaDetails.socialMediaDescription}
          />
          {/* End standard metadata tags */}
          {/* Facebook tags */}
          <meta property="og:type" content={"shared chatbot"} />
          <meta
            property="og:title"
            content={socialMediaDetails.socialMediaTitle}
          />
          <meta
            property="og:description"
            content={socialMediaDetails.socialMediaDescription}
          />
          <meta
            property="og:image"
            content={socialMediaDetails.socialMediaCustomImage}
          />

          {/* End Facebook tags */}
          {/* Twitter tags */}
          <meta name="twitter:creator" content={"Dropchat"} />
          <meta name="twitter:card" content={"Shared Chatbot"} />
          <meta
            name="twitter:image"
            content={socialMediaDetails.socialMediaCustomImage}
          />
          <meta
            name="twitter:title"
            content={socialMediaDetails.socialMediaTitle}
          />
          <meta
            name="twitter:description"
            content={socialMediaDetails.socialMediaDescription}
          />
          {/* End Twitter tags */}
        </Helmet>
      )}
      <BookChatStyles.MainContainer fluid>
        {!isInlineForm &&
        (showLeadForm === "optional" ||
          showLeadForm === "required" ||
          showConditionalLeadForm) &&
        !userLoggedInChatBot ? (
          <BookChatStyles.RightColumn>
            <ChatCommanHeader
              leadThemeSettings={leadThemeSettings}
              leadSettings={leadSettings}
              chatbotDetails={chatbotDetails}
            />

            <BookChatStyles.ChatInputBox>
              <BookChatStyles.RightContentRow>
                <BookChatStyles.ChatBotContent sm={12}>
                  <BookChatStyles.LeadFormScrollDiv>
                    <BookChatStyles.QuestionAnswerSection>
                      {showLeadForm === "conditional" && (
                        <BookChatStyles.FormConditionalHeading>
                          {leadSettings.conditionalMessage}
                        </BookChatStyles.FormConditionalHeading>
                      )}
                      <BookChatStyles.FormHeading>
                        {leadSettings.customText}
                      </BookChatStyles.FormHeading>
                      {leadSettings.isUserName && (
                        <BookChatStyles.InputSection>
                          <BookChatStyles.FormLabelText>
                            {leadSettings.nameText}
                          </BookChatStyles.FormLabelText>
                          <BookChatStyles.FormLeadInput
                            onChange={(e: any) => setNameValue(e.target.value)}
                          />
                        </BookChatStyles.InputSection>
                      )}
                      {leadSettings.isEmail && (
                        <BookChatStyles.InputSection>
                          <BookChatStyles.FormLabelText>
                            {leadSettings.emailText}
                          </BookChatStyles.FormLabelText>
                          <BookChatStyles.FormLeadInput
                            onChange={(e: any) => setEmailValue(e.target.value)}
                          />
                        </BookChatStyles.InputSection>
                      )}
                      {leadSettings.isMobileNumber && (
                        <BookChatStyles.InputSection>
                          <BookChatStyles.FormLabelText>
                            {leadSettings.mobileNumberText}
                          </BookChatStyles.FormLabelText>
                          <BookChatStyles.FormLeadInput
                            maxLength={13}
                            onChange={(e: any) => setPhoneValue(e.target.value)}
                          />
                        </BookChatStyles.InputSection>
                      )}
                    </BookChatStyles.QuestionAnswerSection>
                  </BookChatStyles.LeadFormScrollDiv>
                </BookChatStyles.ChatBotContent>
                <BookChatStyles.FieldColumn sm={12}>
                  <BookChatStyles.LeadChatForm>
                    {leadSettings.loginForm === "optional" && (
                      <BookChatStyles.SkipText
                        onClick={() => {
                          trackEvent({
                            email: emailValue || "PUBLIC",
                            eventCategory: EventCategory.CHATBOT,
                            event:
                              ShareChatbotEvents.USER_SKIP_LOGIN_FORM_ON_SHARE_CHAT,
                            subCategory: SubCategory.EVENT,
                            source: Source.SHARE,
                            bookId: bookId,
                            chatbotUserEmail: chatbotUserEmail,
                            leadChatSession:
                              currentChatSession && currentChatSession.id,
                          });
                          setShowLeadForm("none");
                        }}
                      >
                        Skip Form
                      </BookChatStyles.SkipText>
                    )}
                    <BookChatStyles.FieldSection>
                      <BookChatStyles.SaveButton
                        type="button"
                        onClick={() => {
                          trackEvent({
                            email: emailValue || "PUBLIC",
                            eventCategory: EventCategory.CHATBOT,
                            event:
                              ShareChatbotEvents.USER_FILL_LOGIN_FORM_ON_SHARE_CHAT,
                            subCategory: SubCategory.EVENT,
                            source: Source.SHARE,
                            rawData: {
                              name: nameValue,
                              email: emailValue,
                              phone: phoneValue,
                            },
                            bookId: bookId,
                            chatbotUserEmail: chatbotUserEmail,
                            leadChatSession:
                              currentChatSession && currentChatSession.id,
                          });
                          if (validatedLoginSettings())
                            createUpdateChatSession(true);
                        }}
                      >
                        START CHAT
                      </BookChatStyles.SaveButton>
                    </BookChatStyles.FieldSection>
                    <a
                      href={
                        leadSettings.footerBrandingLink ||
                        "https://dropchat.co/"
                      }
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <BookChatStyles.PoweredText>
                        {leadSettings.footerBranding}
                      </BookChatStyles.PoweredText>
                    </a>
                  </BookChatStyles.LeadChatForm>
                </BookChatStyles.FieldColumn>
              </BookChatStyles.RightContentRow>
            </BookChatStyles.ChatInputBox>
          </BookChatStyles.RightColumn>
        ) : (showLeadForm === "optional" ||
            showLeadForm === "required" ||
            showLeadForm === "none" ||
            !showConditionalLeadForm) &&
          showSampleQuestions ? (
          <SuggestedQuestionsSharedIframeSection
            leadSettings={leadSettings}
            leadThemeSettings={leadThemeSettings}
            setShowSampleQuestions={setShowSampleQuestions}
            sampleQuestions={sampleQuestions}
            waiverSettings={waiverSettings}
            firstMessageAgreePopup={firstMessageAgreePopup}
            questionValue={questionValue}
            setShowAgreePopup={setShowAgreePopup}
            chatStart={chatStart}
            setQuestionValue={setQuestionValue}
            setFormRef={setFormRef}
            allQuestionsandAnswers={currentSessionHistory}
            customGreetingText={customGreetingText}
            formRef={formRef}
            handleIncSampleQuestionCount={handleIncSampleQuestionCount}
            isMessageInterface={isMessageInterface}
            isResponseOn={isResponseOn}
            source="SHARE"
            emailValue={emailValue}
            bookId={bookId}
            chatbotUserEmail={chatbotUserEmail}
            leadChatSession={currentChatSession}
            chatbotDetails={chatbotDetails}
          />
        ) : (
          <>
            <BookChatStyles.RightColumn sm={!isMobileWidth && 12}>
              <BookChatStyles.ChatPageHeader
                className="App-header"
                style={{
                  background: leadThemeSettings.titleBackgroundColor
                    ? leadThemeSettings.titleBackgroundColor
                    : "#1585b5",
                }}
              >
                <Col sm={3} style={{ alignItems: "center" }}>
                  <img src={BubbleChatLiveIcon} alt="online..." width={30} />{" "}
                  &nbsp;
                  <BookChatStyles.ChatPageBold>
                    {chatbotDetails &&
                    chatbotDetails.chatbotHeaderTitle &&
                    chatbotDetails.chatbotHeaderTitle.length > 0
                      ? chatbotDetails.chatbotHeaderTitle
                      : "Welcome to Dropchat"}
                  </BookChatStyles.ChatPageBold>
                </Col>
                <Col sm={8} lg={8} style={{ alignItems: "center" }}></Col>

                <Col
                  sm={1}
                  style={{
                    flex: 1,
                    justifyContent: "end",
                    marginRight: "26px",
                  }}
                >
                  {sampleQuestions && sampleQuestions.length > 0 && (
                    <img
                      src={BulbVector}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        trackEvent({
                          email: emailValue || "PUBLIC",
                          eventCategory: EventCategory.CHATBOT,
                          event:
                            ShareChatbotEvents.USER_VIEW_SUGGESTED_QUESTIONS_ON_SHARE_CHAT,
                          subCategory: SubCategory.EVENT,
                          source: Source.SHARE,
                          bookId: bookId,
                          chatbotUserEmail: chatbotUserEmail,
                          leadChatSession:
                            currentChatSession && currentChatSession.id,
                          rawData: {
                            suggestedQuestions:
                              sampleQuestions && sampleQuestions.length > 0
                                ? (sampleQuestions.map(
                                    (question: { question: string }) =>
                                      question.question
                                  ) as string[])
                                : [],
                          },
                        });
                        setShowSampleQuestions(true);
                      }}
                      alt={"show"}
                    />
                  )}
                </Col>
              </BookChatStyles.ChatPageHeader>

              <BookChatStyles.ChatInputBox sm={12}>
                <BookChatStyles.RightContentRow>
                  <BookChatStyles.ChatBotContent sm={12}>
                    <BookChatStyles.ScrollDivForChatPage
                      className="reactBottomScroll pt-0"
                      isGreeting={
                        customGreetingText &&
                        customGreetingText.isGreeting &&
                        currentSessionHistory &&
                        currentSessionHistory.length === 0
                      }
                    >
                      {localBook.indexStatus !== 2 &&
                      (localBook.type === "custom" ||
                        localBook.chatType === "dropchat") ? (
                        <BookChatStyles.StatusLoaderSection>
                          <img src={LoaderGif} alt="loading..." width={60} />
                          <BookChatStyles.WaitTitle>
                            Please Wait
                          </BookChatStyles.WaitTitle>
                          <BookChatStyles.WaitDescription>
                            {fileProcessingMessage &&
                            fileProcessingMessage.length > 0
                              ? fileProcessingMessage
                              : ` Hello! We wanted to let you know that it will take a few
                        minutes to process this item. Don't worry, though - once
                        the file processing is finished, you'll be able to ask
                        any questions you have. You can leave this screen and
                        return a few minutes later to check status. Or you can
                        wait for status to chance to “Ready” on main page.`}
                          </BookChatStyles.WaitDescription>
                        </BookChatStyles.StatusLoaderSection>
                      ) : (
                        <BookChatStyles.QuestionAnswerSection>
                          <BubbleCustomGreetingText
                            leadThemeSettings={leadThemeSettings}
                            customGreetingText={customGreetingText}
                            isMessageInterface={isMessageInterface}
                          />
                          {currentSessionHistory &&
                            currentSessionHistory.length > 0 &&
                            currentSessionHistory.map(
                              (data: any, index: any) => {
                                return (
                                  <BookChatStyles.SignleQuestionAnswerSection>
                                    <div
                                      style={{
                                        maxWidth: isMessageInterface
                                          ? "80%"
                                          : "100%",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <img
                                        width={"25"}
                                        src={UserImage}
                                        alt="user_image"
                                      />
                                      &nbsp; &nbsp;
                                      <BookChatStyles.QuestionSection
                                        style={{
                                          background:
                                            leadThemeSettings.questionBackgroundColor
                                              ? leadThemeSettings.questionBackgroundColor
                                              : "#fff",

                                          maxWidth: isMessageInterface
                                            ? "80%"
                                            : "100%",
                                        }}
                                      >
                                        &nbsp;&nbsp;
                                        <h5
                                          style={{
                                            fontSize: isMobileWidth
                                              ? "0.75rem"
                                              : "1rem",
                                            color:
                                              leadThemeSettings.questionTextColor
                                                ? leadThemeSettings.questionTextColor
                                                : "rgba(51, 48, 60, 1)",
                                          }}
                                        >
                                          {data.question}
                                        </h5>
                                      </BookChatStyles.QuestionSection>
                                    </div>
                                    <div
                                      style={{
                                        alignSelf: isMessageInterface
                                          ? "flex-end"
                                          : "flex-start",
                                        maxWidth: isMessageInterface
                                          ? "80%"
                                          : "100%",

                                        flexDirection: isMessageInterface
                                          ? "row-reverse"
                                          : "row",
                                      }}
                                    >
                                      <img
                                        width="25"
                                        src={ChatBotIcon}
                                        alt="user_image"
                                        style={{
                                          display: "flex",
                                          alignSelf: "baseline",
                                        }}
                                      />
                                      &nbsp; &nbsp;
                                      <BookChatStyles.ResponseSection
                                        style={{
                                          background:
                                            leadThemeSettings.answerBackgroundColor
                                              ? leadThemeSettings.answerBackgroundColor
                                              : "#fff",
                                          alignSelf: isMessageInterface
                                            ? "end"
                                            : "",
                                        }}
                                      >
                                        <div
                                          style={{
                                            flexDirection: isMessageInterface
                                              ? "row-reverse"
                                              : "row",
                                            alignSelf: isMessageInterface
                                              ? "end"
                                              : "",
                                          }}
                                        >
                                          {/*  {data.answer && data.answer.length > 0 ? ( */}
                                          <BookChatStyles.MarkDownContent
                                            color={
                                              leadThemeSettings.answerTextColor
                                                ? leadThemeSettings.answerTextColor
                                                : "rgba(51, 48, 60, 1)"
                                            }
                                          >
                                            <div
                                              style={{
                                                flexDirection: "column",
                                              }}
                                            >
                                              <CustomMarkdown
                                                text={data.answer}
                                              />
                                              {data.isChatEnd ? null : (
                                                <img
                                                  width="30"
                                                  src={TypingLoading}
                                                  alt="..."
                                                />
                                              )}
                                            </div>
                                          </BookChatStyles.MarkDownContent>
                                        </div>
                                      </BookChatStyles.ResponseSection>
                                    </div>
                                    {data.answer &&
                                    data.answer.length > 0 &&
                                    data._id ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          padding: "5px 0",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                          }}
                                        >
                                          <img
                                            width="30"
                                            src={
                                              data.rating === "Good"
                                                ? THUMBUP
                                                : THUMBUPINACTIVE
                                            }
                                            alt="..."
                                            onClick={() => {
                                              trackEvent({
                                                email: emailValue || "PUBLIC",
                                                eventCategory:
                                                  EventCategory.CHATBOT,
                                                event:
                                                  ShareChatbotEvents.USER_USED_THUMB_UP_ON_SHARE_CHAT,
                                                subCategory: SubCategory.EVENT,
                                                source: Source.SHARE,
                                                rawData: {
                                                  question: data.question,
                                                  answer: data.answer,
                                                },
                                                bookId: bookId,
                                                chatbotUserEmail:
                                                  chatbotUserEmail,
                                                leadChatSession:
                                                  currentChatSession &&
                                                  currentChatSession.id,
                                              });
                                              rating(
                                                data._id,
                                                data.rating === "Good"
                                                  ? "NA"
                                                  : "Good"
                                              );
                                            }}
                                            style={{ cursor: "pointer" }}
                                          />
                                          <img
                                            width="30"
                                            src={
                                              data.rating === "Bad"
                                                ? THUMBDOWN
                                                : THUMBDOWNINACTIVE
                                            }
                                            alt="..."
                                            onClick={() => {
                                              trackEvent({
                                                email: emailValue || "PUBLIC",
                                                eventCategory:
                                                  EventCategory.CHATBOT,
                                                event:
                                                  ShareChatbotEvents.USER_USED_THUMB_DOWN_ON_SHARE_CHAT,
                                                subCategory: SubCategory.EVENT,
                                                source: Source.SHARE,
                                                rawData: {
                                                  question: data.question,
                                                  answer: data.answer,
                                                },
                                                bookId: bookId,
                                                chatbotUserEmail:
                                                  chatbotUserEmail,
                                                leadChatSession:
                                                  currentChatSession &&
                                                  currentChatSession.id,
                                              });
                                              rating(
                                                data._id,
                                                data.rating === "Bad"
                                                  ? "NA"
                                                  : "Bad"
                                              );
                                            }}
                                            style={{ cursor: "pointer" }}
                                          />
                                          {showEscEmail && (
                                            <img
                                              width="25"
                                              src={EscalateEmailIcon}
                                              alt="..."
                                              onClick={() => {
                                                handleCheckUserloggedin();
                                                trackEvent({
                                                  email: emailValue || "PUBLIC",
                                                  eventCategory:
                                                    EventCategory.CHATBOT,
                                                  event:
                                                    ShareChatbotEvents.USER_USED_EMAIL_ESCALATE_ON_SHARE_CHAT,
                                                  subCategory:
                                                    SubCategory.EVENT,
                                                  source: Source.SHARE,
                                                  rawData: {
                                                    question: data.question,
                                                    answer: data.answer,
                                                  },
                                                  bookId: bookId,
                                                  chatbotUserEmail:
                                                    chatbotUserEmail,
                                                  leadChatSession:
                                                    currentChatSession &&
                                                    currentChatSession.id,
                                                });
                                                setShowEscEmailPopup(true);
                                                setQuestionId(data._id);
                                                setEscAnswer(data.answer);
                                                setEscQuestion(data.question);
                                              }}
                                              style={{ cursor: "pointer" }}
                                            />
                                          )}
                                          {showChatLive && (
                                            <img
                                              width="25"
                                              src={ChatLiveIcon}
                                              alt="..."
                                              onClick={() => {
                                                trackEvent({
                                                  email: emailValue || "PUBLIC",
                                                  eventCategory:
                                                    EventCategory.CHATBOT,
                                                  event:
                                                    ShareChatbotEvents.USER_USED_LIVE_CHAT_ESCALATE_ON_SHARE_CHAT,
                                                  subCategory:
                                                    SubCategory.EVENT,
                                                  source: Source.SHARE,
                                                  rawData: {
                                                    question: data.question,
                                                    answer: data.answer,
                                                  },
                                                  bookId: bookId,
                                                  chatbotUserEmail:
                                                    chatbotUserEmail,
                                                  leadChatSession:
                                                    currentChatSession &&
                                                    currentChatSession.id,
                                                });
                                                chatLive(
                                                  currentChatSession.id,
                                                  bookId
                                                );
                                              }}
                                              style={{ cursor: "pointer" }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    ) : null}
                                  </BookChatStyles.SignleQuestionAnswerSection>
                                );
                              }
                            )}

                          {/* ---------------here the Inline Form Component to Show Condtional  ------------- */}
                          {!userLoggedInChatBot &&
                            !loading &&
                            isInlineForm &&
                            (showLeadForm === "optional" ||
                              showLeadForm === "required" ||
                              (!isResponseOn && showConditionalLeadForm)) && (
                              <div
                                style={{
                                  alignSelf: isMessageInterface
                                    ? "flex-end"
                                    : "flex-start",
                                  maxWidth: isMessageInterface ? "80%" : "100%",

                                  flexDirection: isMessageInterface
                                    ? "row-reverse"
                                    : "row",
                                }}
                              >
                                <img
                                  width={"25"}
                                  src={UserImage}
                                  alt="user_image"
                                  style={{
                                    display: "flex",
                                    alignSelf: "baseline",
                                  }}
                                />
                                &nbsp; &nbsp;
                                <BookChatStyles.ResponseSection
                                  style={{
                                    background:
                                      leadThemeSettings.answerBackgroundColor
                                        ? leadThemeSettings.answerBackgroundColor
                                        : "#fff",
                                    alignSelf: isMessageInterface ? "end" : "",
                                  }}
                                >
                                  <InlineMethodLoginForm
                                    emailValue={emailValue}
                                    nameValue={nameValue}
                                    phoneValue={phoneValue}
                                    bookId={bookId}
                                    chatbotUserEmail={chatbotUserEmail}
                                    currentChatSession={currentChatSession}
                                    validatedLoginSettings={
                                      validatedLoginSettings
                                    }
                                    createUpdateChatSession={
                                      createUpdateChatSession
                                    }
                                    setNameValue={setNameValue}
                                    setEmailValue={setEmailValue}
                                    setPhoneValue={setPhoneValue}
                                    setShowLeadForm={setShowLeadForm}
                                    leadSettings={leadSettings}
                                    setCurrentSessionHistory={
                                      setCurrentSessionHistory
                                    }
                                    currentSessionHistory={
                                      currentSessionHistory
                                    }
                                  />
                                </BookChatStyles.ResponseSection>
                              </div>
                            )}
                        </BookChatStyles.QuestionAnswerSection>
                      )}
                    </BookChatStyles.ScrollDivForChatPage>
                  </BookChatStyles.ChatBotContent>
                </BookChatStyles.RightContentRow>
              </BookChatStyles.ChatInputBox>
              <BookChatStyles.FieldColumnForChatPage sm={12}>
                {(showOverlay ||
                  (localBook.chatType === "dropchat" &&
                    localBook.indexStatus !== 2)) && (
                  <BookChatStyles.OverlayFieldColumn
                    sm={12}
                  ></BookChatStyles.OverlayFieldColumn>
                )}

                <Formik
                  initialValues={{
                    prompt: questionValue,
                  }}
                  onSubmit={(values, actions) => {
                    if (firstMessageAgreePopup && waiverSettings.isWaiver) {
                      setShowAgreePopup(true);
                    } else {
                      setQuestionValue("");
                      submitQuestion(values, actions);
                      setIsResponseOn(true);
                    }
                  }}
                  enableReinitialize
                >
                  {({ errors, touched, isValidating }: any) => (
                    <BookChatStyles.BookFetchForm ref={(el) => setFormRef(el)}>
                      <BookChatStyles.FieldSection>
                        <BookChatStyles.FormInput
                          name="prompt"
                          as="textarea"
                          disabled={isResponseOn}
                          placeholder={"Ask me anything"}
                          value={questionValue}
                          onChange={(e) => setQuestionValue(e.target.value)}
                          style={{
                            visibility:
                              !userLoggedInChatBot &&
                              isInlineForm &&
                              (showLeadForm === "optional" ||
                                showLeadForm === "required" ||
                                (!isResponseOn && showConditionalLeadForm))
                                ? "hidden"
                                : "visible",
                            paddingRight: "2.6rem",
                            resize: "none",
                            height: "50px",
                            border:
                              leadThemeSettings.questionTextFieldColor &&
                              "2px solid " +
                                leadThemeSettings.questionTextFieldColor,
                            color: leadThemeSettings.questionTextFieldColor
                              ? leadThemeSettings.questionTextFieldColor
                              : "#000",
                          }}
                          onKeyDown={(e) => {
                            // eslint-disable-next-line
                            if (e.keyCode == 13 && e.shiftKey == false) {
                              e.preventDefault();
                              if (!isResponseOn) formRef.requestSubmit();
                            }
                          }}
                        />
                        <BookChatStyles.FetchBookButton
                          type={
                            isResponseOn || questionValue.length === 0
                              ? "button"
                              : "submit"
                          }
                        >
                          <img
                            style={{
                              visibility:
                                !userLoggedInChatBot &&
                                isInlineForm &&
                                (showLeadForm === "optional" ||
                                  showLeadForm === "required" ||
                                  (!isResponseOn && showConditionalLeadForm))
                                  ? "hidden"
                                  : "visible",
                            }}
                            width={25}
                            src={SearchArrow}
                            alt="search"
                          />
                        </BookChatStyles.FetchBookButton>
                      </BookChatStyles.FieldSection>
                      <div style={{ justifyContent: "center" }}>
                        <a
                          href={
                            (chatbotDetails &&
                              chatbotDetails.footerBrandingLink) ||
                            "https://dropchat.co/"
                          }
                          rel="noreferrer"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <BookChatStyles.PoweredText>
                            {chatbotDetails && chatbotDetails.footerBrandingText
                              ? chatbotDetails.footerBrandingText
                              : "Powered By Dropchat"}
                          </BookChatStyles.PoweredText>
                        </a>
                      </div>
                    </BookChatStyles.BookFetchForm>
                  )}
                </Formik>
              </BookChatStyles.FieldColumnForChatPage>
            </BookChatStyles.RightColumn>
          </>
        )}

        {showLoginPopup && (
          <>
            <Modal show={true} backdrop="static" keyboard={false}>
              <MagicLinkLoginPopup showLoginPopup={showLoginPopup} />
            </Modal>
          </>
        )}
      </BookChatStyles.MainContainer>
      <AgreeComponent
        submitTerms={submitTerms}
        showAgreePopup={showAgreePopup}
        waiverSettings={waiverSettings}
      />
      <EscalateEmailPopup
        showEscEmailPopup={showEscEmailPopup}
        setShowEscEmailPopup={setShowEscEmailPopup}
        questionId={questionId}
        bookId={bookId}
        escAnswer={escAnswer}
        escQuestion={escQuestion}
        escSource="SHARE"
        chatbotUserEmail={chatbotUserEmail}
        emailValue={emailValue}
        title={localBook.title}
        leadChatSession={currentChatSession}
        userLoggedDetails={userLoggedDetails && userLoggedDetails}
        userFullName={userFullName && userFullName}
      />
    </>
  );
};

export default SharedBookChat;
