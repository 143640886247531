// import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BookContext } from "../../context/BookContext";
import { UserContext } from "../../context/UserContext";
// import { LoaderContext } from "../../context/loader-context";
import { PaginationContext } from "../../context/pagination-context";
import myAxios from "../../myaxios";
// import { parseErrorResponse } from "../../utils/utils";
import MainHeader from "../header";
import SubscriptionExpiredPopup from "../subscription-expired-popup";
import SubscriptionLimitPopup from "../subscription-limit-popup";
import BookLibrary from "./book-library";
// import BookSearch from "./book-search";
// import BookSearchList from "./book-search-list";
import {
  DEVICE_MOBILE,
  MEDIUM,
  MOBILE,
  TAB,
} from "../../constants/breakpoints";
import { PublicContext } from "../../context/PublicContext";
import { LoaderContext } from "../../context/loader-context";
import useDebounce from "../../use-debounce";
import CreateDropchatBook from "./create-book";
import { TeamContext } from "../../context/team-context";
import { PlanContext } from "../../context/plan-context";
import { StatusLoaderSection, WaitTitle } from "../app-loader";
import LoaderGif from "../../images/loader.gif";

// import { Mixpanel } from "../../mixpanel";
// eslint-disable-next-line
declare var _cio: any;

// const Title = styled.p`
//   color: #154433;
//   font-size: 14px;
//   font-weight: bold;
//   padding: 0px 12px;
//   margin: 1rem 0rem;
//   text-align: center;
// `;

const LeftDiv = styled(Col)`
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 12px;
  position: relative;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-bottom: 10px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-bottom: 10px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-bottom: 0px;
  }
`;

const LeftDivMain = styled(Col)`
  flex-direction: column;
  position: relative;
  flex: 1;
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: max-content;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: max-content;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    height: auto;
  }
`;

const RightDivMain = styled(Col)`
  flex-direction: row;
`;

const RightDiv = styled(Col)`
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 12px;
  position: relative;
  flex: 1;
  width: 100%;
`;

const BookHeadingText = styled.h4`
  font-size: 16px;
  color: #797979;
  font-weight: bold;
`;

const ListRow = styled(Row)`
  width: 100%;
  height: 100%;
  margin: 0;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: auto;
  }
`;

export const WaitTitleNew = styled.p`
  text-align: center;
  font-size: 20px;
  color: #4f83b5;
  font-weight: bold;
`;

// const LeftSectionDiv = styled.div`
//   flex-direction: column;
//   flex: 1;
// `;

// const RightSectionDiv = styled.div`
//   flex-direction: column;
//   flex: 1;
// `;

// const SquareBox = styled(Col)`
//   flex-direction: column;
//   padding: 2rem;
//   background: ${(props) => (props.background ? "#f7fafbcc" : "#ffffff")};
//   border: 0.2px solid rgba(21, 133, 181, 0.2);
//   box-shadow: 0px 0px 10px rgba(21, 133, 181, 0.15);
//   border-radius: 10px;
//   margin: 1rem;
//   min-height: 220px;
//   justify-content: center;
// `;

// const SquareBoxTitle = styled.p`
//   color: #33303c;
//   font-size: 18px;
//   font-weight: 500;
// `;

// const SquareBoxDescription = styled.p`
//   color: #33303c;
//   font-size: 16px;
//   font-size: normal;
//   margin: 0;
// `;
// const ParagraphSection = styled.p`
//   color: #33303c;
//   font-size: 30px;
//   font-weight: bold;
//   margin-top: 1.5rem;
//   & span {
//     color: #1585b5;
//   }
// `;

// const DescriptionContent = styled.p`
//   color: #282c34;
//   font-size: 20px;
//   font-weight: 500;
// `;

const SubscriptionExpireOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
`;

const AddSelfBookPara = styled.p`
  color: #282c34;
  font-size: 16px;
  font-weight: 500;
`;
// const ROW = styled(Row)`
//   width: 100%;
//   @media (max-width: ${MOBILE.maxWidth}px) {
//   }
// `;

const BookArea = ({ loadingState }: any) => {
  const { deviceWidth } = useContext(PublicContext);
  const {
    noFoBots,
    setNoOfBots,
    books,
    setBooks,
    totalPage,
    setTotalPage,
    setNoOfTeamBooks,
    noOfTeamBooks,
  } = useContext(BookContext);
  const [userSubs, setUserSubs] = useState<any>();
  const { activePlan, setPlansList, setActivePlan, setLoggedInUserPlan } =
    useContext(PlanContext);
  const [isUserCanChange, setIsUserCanChange] = useState<boolean>(true);
  const { selectedTeam } = useContext(TeamContext);
  // const { setShowLoader } = useContext(LoaderContext);
  // eslint-disable-next-line
  // const [query, setQuery] = useState("");
  // eslint-disable-next-line
  // const [allBooks, setAllBooks] = useState<any>({ items: [] });
  const { setShowLoader } = useContext(LoaderContext);

  const [hideCurrentLoader, setHideCurrentLoader] = useState<boolean>();
  const {
    userProfileSettings,
    user,
    totalBooksCount,
    setTotalBooksCount,
    updateUserSubscription,
    setUserProfileSettings,
  } = useContext(UserContext);
  const { pageLimit, setPageLimit, pageSkip, setPageSkip } =
    useContext(PaginationContext);
  // eslint-disable-next-line
  const [bookError, setBookError] = useState(false);
  // eslint-disable-next-line
  const [allowedInputsForUser, setAllowedInputsForUser] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(0);
  const [setAllIndexIds] = useState<any>([]);
  const [bookCurrentPage, setBookCurrentPage] = useState<number>(0);
  // eslint-disable-next-line
  const [selectedBookByUser, setSelectedBookByUser] = useState<any>();
  // eslint-disable-next-line
  const [isBookFound, setIsBookFound] = useState(true);

  const [resetPagination, setResetPagination] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string>('')

  const navigate = useNavigate();

  useDebounce(
    () => {
      if (!user) {
        navigate("/verifying-credentials");
      } else {
        // setShowLoader(true);
        setHideCurrentLoader(false);
        if (selectedTeam) {
          myAxios
            .post(process.env.REACT_APP_SERVER_URL + "/books/paginated-books", {
              team: selectedTeam._id,
              pagination: { limit: pageLimit, skip: pageSkip },
            })
            .then((response) => {
              setBooks(response.data.data.books);
              setTotalPage(response.data.data.count);
              setNoOfBots(response.data.data.count);
              setNoOfTeamBooks(
                response.data.data.totalTeamBooks.length > 0
                  ? response.data.data.totalTeamBooks.length
                  : 0
              );
              // setShowLoader(false);
              myAxios
                .get(process.env.REACT_APP_SERVER_URL + "/books/count")
                .then((response) => {
                  setTotalBooksCount(response.data.data.totalBooksCount);
                })
                .catch((error) => { });
              myAxios
                .get(`${process.env.REACT_APP_SERVER_URL}/plans`)
                .then((res) => {
                  if (res.data.success) {
                    // user state need to update
                    updateUserSubscription(res.data.data.currentSubscription);
                    setUserSubs(res.data.data);
                    setShowLoader(false);
                    setHideCurrentLoader(true);
                    setActivePlan(res.data.data.currentSubscription);
                    setLoggedInUserPlan(
                      res.data.data.loggedInUserPlans.currentSubscription
                    );
                  }
                });
              myAxios
                .get(process.env.REACT_APP_SERVER_URL + "/user/user-profile")
                .then((response) => {
                  if (response.data && response.data.data) {
                    setUserProfileSettings(response.data.data);
                  }
                })
                .catch((error) => {
                  // handle error
                  // console.log("user settings error, ", error.response.data);
                });
            })
            .catch((error) => {
              setShowLoader(false);
            });
        } else {
          setHideCurrentLoader(true);
        }
      }
      // eslint-disable-next-line
    },
    [selectedTeam, userProfileSettings.totalCalculatedBooks],
    500
  );
  console.log("userRole is, ", userRole === 'owner');


  useEffect(() => {
    // console.log("selectedTeam", selectedTeam);
    if (selectedTeam) {
      let currentTeamMember: any =
        selectedTeam &&
        selectedTeam.members &&
        selectedTeam?.members.find((m: any) => m.email === user?.email);
      currentTeamMember && setUserRole(currentTeamMember.role)
      // console.log("userRole is, ", userRole);
      if (
        (currentTeamMember && currentTeamMember.role === "owner") ||
        (currentTeamMember && currentTeamMember.role === "admin") ||
        (currentTeamMember && currentTeamMember.role === "member")
      ) {
        setIsUserCanChange(true);
      } else {
        setIsUserCanChange(false);
      }
    } else {
      setIsUserCanChange(true);
    }
    // eslint-disable-next-line
  }, [selectedTeam]);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.usetiful.com/dist/usetiful.js";
    script.setAttribute("id", "usetifulScript");
    script.dataset.token = process.env.REACT_APP_USETIFUL_TOKEN;

    document.head.appendChild(script);

    return () => {
      // Cleanup the script if the component unmounts
      document.getElementById("usetifulScript")?.remove();
    };
  }, []); // Empty dependency array ensures this runs only once on mount

  // console.log("hideCurrentLoader : ", hideCurrentLoader)

  return (
    <>
      <MainHeader isUserPage={true} activeFrom={"fromMain"} />
      <Container
        fluid
        style={{ marginTop: "1%", marginBottom: "1%", height: "100%" }}
      >
        <Row
          style={{
            width: deviceWidth !== DEVICE_MOBILE ? "100%" : "",
            margin: "0",
            display: "flex",
          }}
        >
          {isUserCanChange && (
            <LeftDivMain
              lg={5}
              style={{
                padding: "0",
              }}
            >
              <LeftDiv>
                {activePlan ? (
                  <>
                    {activePlan && activePlan ? (
                      activePlan.meta && activePlan?.meta?.noOfBooks ? (
                        activePlan?.meta?.noOfBooks <
                          0 ? null : noOfTeamBooks &&
                            noOfTeamBooks >= activePlan?.meta?.noOfBooks ? (
                          <SubscriptionExpireOverlay>
                            <SubscriptionLimitPopup
                              restrictionMessage={`Your ${activePlan.meta.noOfBooks
                                } ${activePlan.meta.noOfBooks > 1
                                  ? "chatbots"
                                  : "chatbot"
                                } plan limit has been exceeded!`}
                              messageregardingrest={`For more chatbots please change your plan.`}
                              highliter={`isBookHighliter`}
                            ></SubscriptionLimitPopup>
                          </SubscriptionExpireOverlay>
                        ) : null
                      ) : null
                    ) : (
                      <SubscriptionExpireOverlay>
                        <>
                          {userRole === 'owner' ?
                            <SubscriptionExpiredPopup
                              restrictionMessage={`It looks like you don't currently have a personal subscription, but you still have access to shared chatbots through your team's subscription. To access the team chatbots shared with you, click your email address in the top-right corner!`}
                              // messageregardingrest={`To create more chatbots please purchase a plan.`}
                              highliter={`isBookHighliter`}
                            ></SubscriptionExpiredPopup>
                            :
                            <SubscriptionExpiredPopup
                              restrictionMessage={`It looks like your team owner don't currently have a personal subscription, but you still have access to other shared chatbots through your team's subscription. To access the other team chatbots shared with you, click your email address in the top-right corner!`}
                              // messageregardingrest={`To create more chatbots please purchase a plan.`}
                              highliter={`isBookHighliter`}
                            ></SubscriptionExpiredPopup>
                          }
                        </>
                      </SubscriptionExpireOverlay>
                    )}

                    <AddSelfBookPara>
                      Have a PDF file or website you want to chat with?
                    </AddSelfBookPara>
                    <Container fluid style={{ padding: "0" }}>
                      <Col sm={12} xs={12} md={12} lg={12}>
                        <CreateDropchatBook
                          setAllowedInputsForUser={setAllowedInputsForUser}
                          setAllIndexIds={setAllIndexIds}
                          bookCurrentPage={bookCurrentPage}
                          setTotalPage={setTotalPage}
                          setNoOfTeamBooks={setNoOfTeamBooks}
                          setResetPagination={setResetPagination}
                        />
                      </Col>
                    </Container>

                    {bookError && (
                      <Container>
                        <BookHeadingText>
                          Book not found. Please try again!
                        </BookHeadingText>
                      </Container>
                    )}
                  </>
                ) : (
                  <>
                    {!hideCurrentLoader ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "770px",
                          height: "850px",
                        }}
                      >
                        <img src={LoaderGif} alt="loading..." width={30} />
                        <WaitTitleNew>Please Wait</WaitTitleNew>
                      </div>
                    ) : (
                      <SubscriptionExpireOverlay>
                        {userRole === 'owner' ?
                          <SubscriptionExpiredPopup
                            restrictionMessage={`It looks like you don't currently have a personal subscription, but you still have access to shared chatbots through your team's subscription. To access the team chatbots shared with you, click your email address in the top-right corner!`}
                            // messageregardingrest={`To create more chatbots please purchase a plan.`}
                            highliter={`isBookHighliter`}
                          ></SubscriptionExpiredPopup>
                          :
                          <SubscriptionExpiredPopup
                            restrictionMessage={`It looks like your team owner don't currently have a personal subscription, but you still have access to other shared chatbots through your team's subscription. To access the other team chatbots shared with you, click your email address in the top-right corner!`}
                            // messageregardingrest={`To create more chatbots please purchase a plan.`}
                            highliter={`isBookHighliter`}
                          ></SubscriptionExpiredPopup>
                        }
                      </SubscriptionExpireOverlay>
                    )}
                  </>
                )}
              </LeftDiv>
            </LeftDivMain>
          )}

          <RightDivMain
            lg={isUserCanChange ? 7 : 12}
            md={12}
            xs={12}
            style={{
              padding: deviceWidth === DEVICE_MOBILE ? "0" : "",
              paddingRight: "0",
            }}
          >
            <RightDiv>
              {books && books.length > 0 ? (
                <ListRow>
                  <BookLibrary
                    allBooks={books}
                    bookCurrentPage={bookCurrentPage}
                    setBookCurrentPage={setBookCurrentPage}
                    totalPage={
                      userProfileSettings.totalCalculatedBooks
                        ? userProfileSettings.totalCalculatedBooks
                        : noFoBots
                    }
                    setTotalPage={setTotalPage}
                    pageLimit={pageLimit}
                    setPageLimit={setPageLimit}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    resetPagination={resetPagination}
                    setResetPagination={setResetPagination}
                    isUserCanChange={isUserCanChange}
                  />
                </ListRow>
              ) : (
                <></>
              )}
            </RightDiv>
          </RightDivMain>
        </Row>
      </Container>
    </>
  );
};

export default BookArea;
